import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/en';
import ContactFormEn from '../components/contact-us/ContactFormEn';
import SEO from '../components/seo';

const ContactUs = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Contact us - You want to tell us what you think of our boards?"
      keywords={['snowboard', 'boards', 'polyvalence']}
      description="Our team will answer all your questions with pleasure."
      pathname="/en/contact-us/"
    />
    <div className="contactPage">
      <header className="masthead">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-3">Contact us</h1>
                <p className="lead mb-3">We answer in 2/3 business days</p>
                <ContactFormEn />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </Layout>
);

export default ContactUs;
