import React, { Component } from 'react';

export class ContactForm extends Component {
  render() {
    return (
      <form
        method="post"
        name="contact-en"
        action="/en/success"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact-en" />
        <div className="form-group">
          <label>Name</label>
          <input
            className="form-control form-control-lg"
            type="text"
            name="name"
            id="name"
            required
            placeholder="Your name"
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            className="form-control form-control-lg"
            type="email"
            name="email"
            id="email"
            required
            placeholder="Your email"
          />
        </div>
        <div className="form-group">
          <label>Subject</label>
          <input
            className="form-control form-control-lg"
            type="text"
            name="subject"
            id="subject"
            required
            placeholder="Your request"
          />
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea
            className="form-control form-control-lg"
            name="message"
            id="message"
            rows="5"
            required
            placeholder="Your message"
          />
        </div>
        <input className="btn btn-primary btn-lg mt-3" type="reset" value="Clear" />
        <button className="btn btn-warning btn-lg mt-3 mr-3" type="submit">
          Send
        </button>
      </form>
    );
  }
}

export default ContactForm;
